import { css } from '@emotion/react';
import { textStyle } from '~/styles/textStyle';
import { colors } from '~/styles/colors';
import { layout } from '~/styles/layout';

export const label = (args: { bold: boolean }) => css`
  ${textStyle('m', args.bold ? 'bold' : 'regular')}

  display: flex;
  align-items: center;
  line-height: 32px;
`;

export const labelDesc = css`
  ${textStyle('xs', 'regular')};

  line-height: 24px;
  color: ${colors.gray3};
  border-radius: ${layout.baseSize.borderRadius}px;

  & > li::before {
    content: '＊';
    margin-right: 2px;
  }
`;

export const optionalLabel = css`
  ${textStyle('xs', 'regular')}

  line-height: 14px;
  padding: 4px 8px;
  margin-left: 8px;
  text-align: center;
  display: inline-block;
  color: ${colors.white};
  border-radius: 4px;
  background-color: ${colors.gray4};

  &::before {
    content: '任意';
  }
`;

export const labelAction = css`
  margin-left: 8px;
`;

export const errorMessage = css`
  ${textStyle('s', 'bold')};

  color: ${colors.alert};
  margin-top: 8px;
  white-space: pre-wrap;
`;
