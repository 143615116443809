import React from 'react';
import * as styles from './index.styles';

export type Props = {
  label?: string;
  labelBold?: boolean;
  htmlFor?: string;
  children?: React.ReactNode;
  labelDesc?: (string | undefined)[];
  labelAction?: React.ReactNode;
  optionalLabel?: boolean;
  errorMessage?: string;
};

export const Label: React.FC<Props> = (props: Props) => {
  return (
    <div>
      {props.label && (
        <label css={styles.label({ bold: !!props.labelBold })} htmlFor={props.htmlFor}>
          {props.label}

          {props.optionalLabel && <div css={styles.optionalLabel} />}

          {props.labelAction && <div css={styles.labelAction}>{props.labelAction}</div>}
        </label>
      )}

      {props.labelDesc && (
        <ul css={styles.labelDesc}>
          {props.labelDesc
            .filter((v) => v !== undefined)
            .map((row, idx) => (
              <li key={idx}>{row}</li>
            ))}
        </ul>
      )}

      {props.children && <div>{props.children}</div>}

      {props.errorMessage && <div css={styles.errorMessage}>{props.errorMessage}</div>}
    </div>
  );
};
